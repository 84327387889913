<!-- eslint-disable vue/no-parsing-error -->
<template>
  <b-row>
    <b-modal
      v-if="selectedItem"
      v-model="updateItem"
      ok-only
      title="Modification date de réception prévue"
      centered
      size="sm"
      :hide-footer="true"
      lazy
      static
      @hidden="$emit('close')"
    >
      <flat-pickr
        v-model="selectedItem.dateShippingMapped"
        class="form-control flat-picker bg-transparent border-0 shadow-none"
        placeholder="YYYY-MM-DD"
      />
      <b-button
        v-if="selectedItem.dateShippingMapped"
        :disabled="loading"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="primary"
        class="my-2"
        @click="update"
      >
        <span class="align-middle">Mettre à jour</span>
      </b-button>
    </b-modal>
    <b-col cols="12">
      <!-- Default Colors -->
      <b-card
        title="Liste des BL"
        class="background-color"
      >
        <b-row>
          <b-col md="4">
            <a href="https://docs.google.com/spreadsheets/d/14CC9ISOG892L8jTJK5lI2_zcT-Wml_pJZENTFCT36hk/" target="_blank"><u>Accéder au template</u></a>
            <h5 class="mt-1">Fichier :</h5>
            <b-form-file
              v-model="file"
              placeholder="Choisir un fichier ou glisser..."
              drop-placeholder="Drop fichier ici..."
              accept=".csv"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-center mt-2"
            md="2"
          >
            <b-form-group>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="upload"
              >
                Lancer l'import
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
            v-if="errors && errors.length"
            class="text-center mt-2"
            md="4"
          >
            <download-csv
              :data="errors"
              name="errors.csv"
            >
              <b-button
                class="mb-2"
                variant="warning"
              >
                Télécharger les {{ errors.length }} erreurs
              </b-button>
            </download-csv>
          </b-col>
        </b-row>
      </b-card>
      <!--/ Gradient color start -->
    </b-col>
    <b-col cols="12">
      <b-card>

        <b-table
          ref="selectableTable"
          selectable
          :items="bls"
          :fields="fields"
        >
          <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template>
          <template #cell(linkOrder)="data">
            <a v-if="data.item.links && data.item.links.length" :href="data.item.links[0]" target="_blank">Order Link</a>
          </template>
          <template #row-details="data">
            <b-table-lite
              responsive
              :items="data.item.value"
              :fields="['sku', 'description', 'quantity', { key: 'remaining', label: 'Remaining' }, 'dateShipping', 'price', 'X']"
            >
              <template #cell(date)="data">
                {{ $moment(data.item.date).format('DD/MM/YYYY') }}
              </template>
              <template #cell(dateShipping)="data">
                {{ $moment(data.item.dateShipping).format('DD/MM/YYYY') }}
              </template>
              <template #cell(X)="data">
                <feather-icon
                  icon="TrashIcon"
                  class="ml-5 text-primary"
                  style="cursor: pointer;"
                  size="20"
                  @click="removeItem(data.item)"
                />
              </template>
            </b-table-lite>
          </template>

          <template #cell(date)="data">
            {{ $moment(data.item.date[0]).format('DD/MM/YYYY') }}
          </template>
          <template #cell(logistic)="data">
            {{ data.item.logistic ? data.item.logistic.filter(l => l !== '')[0] : '' }}
          </template>
          <template #cell(dateShipping)="data">
            <b-badge :variant="`light-${($moment(data.item.dateShipping[0]).isBefore($moment()) || !data.item.dateShipping[0]) && data.item.remaining > 0 ? 'danger' : 'success'}`">
              <span class="align-middle">{{ data.item.remaining < 1 ? 'Livré' : $moment(data.item.dateShipping[0]).isBefore($moment()) ? `Retard depuis ${$moment().diff($moment(data.item.dateShipping[0]), 'days')} jours` : data.item.dateShipping[0] ? $moment(new Date(data.item.dateShipping[0])).format('DD/MM/YYYY') : 'Pas de date' }}</span>
            </b-badge>
            <feather-icon
              v-if="data.item.remaining > 0"
              icon="EditIcon"
              class="ml-1 text-primary"
              style="cursor: pointer;"
              size="20"
              @click="selectItem(data.item)"
            />
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BModal, BBadge, BRow, BCol, BFormGroup, BButton, BFormFile, BTable, BFormCheckbox, BTableLite,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BCard,
    BModal,
    BBadge,
    BFormFile,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BTableLite,
    'download-csv': JsonCSV,
  },
  data() {
    return {
      file: null,
      rangeDate: null,
      updateItem: false,
      selectedItem: null,
      selected: '',
      option: ['desktop', 'mobile'],
      fields: [{ key: 'show_details', label: 'Détails' }, '_id', { key: 'date', label: 'Date Order', sortable: true }, 'logistic', 'linkOrder', { key: 'dateShipping', sortable: true }],
      products: [],
      selectedProduct: '',
      bls: [],
      errors: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getBl()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item
      // eslint-disable-next-line prefer-destructuring
      this.selectedItem.dateShippingMapped = this.selectedItem.dateShipping[0]
      this.updateItem = true
    },
    async update() {
      try {
        this.loading = true
        await this.$http.post('/admin/logistic/bl-date-shipping', this.selectedItem)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Biens sauvegardé !',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getBl()
        this.selectedItem = null
        this.updateItem = false
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async getBl() {
      try {
        this.loading = true
        const {
          bls,
        } = await this.$http.get('/admin/logistic/order-list')
        this.bls = bls
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async upload(f) {
      this.errors = []
      console.log(this.selectedProduct)
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez mettre un fichier.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          this.loading = true
          const { errors } = await this.$http.post('/admin/logistic/bl-upload', form)
          this.file = null
          this.errors = errors
          console.log(errors)
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: `L'import a bien reussi. ${errors.length ? `Mais il y a eu ${errors.length} erreurs.` : ''}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          await this.getBl()
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      }
    },
    async removeItem(item) {
      try {
        console.log(item)
        this.loading = true
        await this.$http.put('/admin/logistic/bl', { sku: item.sku, id: item.id })
        this.loading = false
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Suppression réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getBl()
      } catch (err) {
        console.log(err)
      }
      console.log(item)
    },
  },
}
</script>

  <style lang="scss">
  .colors-list {
    li {
      padding: 0.4rem;
      svg {
        margin-right: 0.25rem;
      }
    }
  }
  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
